import Plugin from "../plugin-system/Plugin";

export default class ServiceBadgePlugin extends Plugin {
    constructor() {
        super('ServiceBadgePlugin');
    }

    /**
     *
     * @param htmlElement
     */
    initPlugin(htmlElement: HTMLElement): boolean {
        let instance = this;

        super.initPlugin(htmlElement);

        let badgeTrigger = this.el.querySelector('.service-badge__trigger');

        if (badgeTrigger !== null) {
            badgeTrigger.addEventListener('click', function () {
                instance.el.classList.toggle('service-badge--open');
            });
        }

        return true;
    }
}

/* global google */

import Plugin from "../plugin-system/Plugin";
import $ from 'jquery';
// @ts-ignore
import PubSub from 'pubsub-js';

export default class GoogleMapsPlugin extends Plugin  {
    protected apiUrl: string;
    protected city: string;
    protected company: string;
    protected map: any;
    protected marker: any;
    protected markerIconUrl: string;
    protected latitude: number;
    protected longitude: number;
    protected street: string;
    protected zip: string;
    protected styles: object;

    constructor() {
        super('GoogleMapsPlugin');

        this.apiUrl = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=';
        this.city = '';
        this.company = '';
        this.markerIconUrl = '/resources/img/hotspot.png';
        this.latitude = 0;
        this.longitude = 0;
        this.street = '';
        this.zip = '';
        this.styles = [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
        ];
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        if (typeof google === 'object' && typeof google.maps === 'object') {
            this.initMap();
        } else if((window as any).googleMapsLoaded) {
            PubSub.subscribe('GoogleMapsPlugin/loadMapsApiFinished', this.initMap.bind(this));
        } else {
            this.loadMapsApi();
        }

        return true;
    }

    loadMapsApi() {
        let me = this,
            dataset = this.el.dataset || {};

        if (dataset.apikey) {
            this.apiUrl += dataset.apikey;
        }

        (window as any).googleMapsLoaded = true;

        $.getScript(me.apiUrl, function () {
            PubSub.publish('GoogleMapsPlugin/loadMapsApiFinished');
            me.initMap();
        });
    }

    initMap() {
        if (this.el === undefined) {
            return false;
        }

        let dataset = this.el.dataset || {},
            me = this as any;

        if (dataset.company) {
            this.company += dataset.company;
        }

        if (dataset.city) {
            this.city = dataset.city;
        }

        if (dataset.latitude) {
            this.latitude = parseFloat(dataset.latitude);
        }

        if (dataset.longitude) {
            this.longitude = parseFloat(dataset.longitude);
        }

        if (dataset.street) {
            this.street = dataset.street;
        }

        if (dataset.zip) {
            this.zip = dataset.zip;
        }

        //Shortcut
        me.el.find = me.el.querySelector;

        let queryParams = window.encodeURIComponent([
            me.company,
            me.street,
            me.zip,
            me.city
        ].join(' ').trim()) + '&z=12';

        me.map = new google.maps.Map(me.el.find('.google-maps__instance') as Element, {
            center: new google.maps.LatLng(me.latitude, me.longitude),
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            zoom: 12,
            zoomControl: false,
            styles: me.styles as any
        });

        me.marker = new google.maps.Marker({
            icon: {
                scaledSize: new google.maps.Size(66, 85),
                url: me.markerIconUrl
            },
            position: new google.maps.LatLng(me.latitude, me.longitude),
            map: me.map
        });

        me.el.find('.placecard__address .address__headline').innerHTML = me.company;
        me.el.find('.placecard__address .address__subline').innerHTML = me.street + ',' + me.zip + ' ' + me.city;
        me.el.find('.placecard__address a').href = 'https://maps.google.com?q=' + queryParams;
        me.el.find('.placecard__directions a').href = 'https://maps.google.com?daddr=' + queryParams;

        me.el.find('.control--fullscreen').addEventListener('click', function () {
            if (document.fullscreenElement == me.el) {
                document.exitFullscreen();
            } else {
                me.el.requestFullscreen();
            }
        });

        me.el.find('.control--zoom-in').addEventListener('click', function () {
            me.map.setZoom(me.map.getZoom() + 1);
        });
        me.el.find('.control--zoom-out').addEventListener('click', function () {
            me.map.setZoom(me.map.getZoom() - 1);
        });

        window.addEventListener('resize', this.resizeMap.bind(this));
        PubSub.subscribe('AccordionPlugin/accordionShown', this.resizeMap.bind(this));
        this.resizeMap();

        return true;
    }

    resizeMap() {
        let scaleFactor = 1;

        if (window.innerWidth >= 1200) {
            scaleFactor = 9 / 16;
        }

        let newHeight = (this.el.getBoundingClientRect().width * scaleFactor) + 'px';

        this.el.style.height = newHeight;

        let mapsContainer = this.el.querySelector('.google-maps__container');
        let mapsInstance = this.el.querySelector('.google-maps__instance');

        if (mapsContainer !== null
            && mapsInstance !== null
        ) {
            (mapsContainer as HTMLElement).style.height = newHeight;
            (mapsInstance as HTMLElement).style.height = newHeight;
        }
    }
}

interface AnimationConfig {
    addClass?: string;
    removeClass?: string;
    callback?: (animator: Animator) => NextStepReturn;
}

interface NextStepReturn {
    return: boolean;
}

export default class Animator {
    protected animationQueue: Record<string, any>;
    protected currentStep: number;
    protected el: HTMLElement;
    protected running: boolean;
    protected transitionCallback: any;

    /**
     *
     * @param _HTMLElement
     */
    constructor(_HTMLElement: HTMLElement) {
        this.animationQueue = [];
        this.currentStep = 0;
        this.el = _HTMLElement;
        this.running = false;
    }

    /**
     *
     * @param config
     */
    public addStep(config: AnimationConfig): this {
        this.animationQueue.push(config);

        return this;
    }

    public run(): void {
        let instance = this;

        this.running = true;
        this.transitionCallback = instance.executeStep.bind(instance);

        this.el.addEventListener('transitionend', this.transitionCallback, true);
        this.executeStep();
    }

    public nextStep(): NextStepReturn {
        if (this.animationQueue.length - 1 >= this.currentStep && this.running) {
            if (this.animationQueue.length - 1 === this.currentStep) {
                this.running = false;
            }

            this.executeStep();
        }

        return {
            return: true
        }
    }

    public triggerReflow(): void {
        this.el.offsetWidth;
    }

    public executeStep(): void {
        let currentStepConfig = this.animationQueue[this.currentStep] as AnimationConfig;

        if (this.animationQueue.length - 1 === this.currentStep) {
            this.currentStep = 0;

            this.el.removeEventListener('transitionend', this.transitionCallback, true);
        }

        this.currentStep++;

        if (currentStepConfig.addClass !== undefined) {
            this.el.classList.add(currentStepConfig.addClass);
        }

        if (currentStepConfig.removeClass !== undefined) {
            this.el.classList.remove(currentStepConfig.removeClass);
        }

        if (currentStepConfig.callback !== undefined) {
            currentStepConfig.callback(this);
        }
    }
}

import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';

export default class ExpanderPlugin extends Plugin {
    protected collapsedCls: string;
    protected collapsedHeight: string;
    protected target: HTMLElement | null;
    protected targetCls: string;
    protected targetCollapsedCls: string;

    constructor() {
        super('ExpanderPlugin');

        this.collapsedCls = 'expander--collapsed';
        this.collapsedHeight = '56px';
        this.targetCls = 'expander-target';
        this.targetCollapsedCls = 'expander-target--collapsed';

        this.target = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let me = this;

        super.initPlugin(htmlElement);

        if (me.el === undefined) {
            return false;
        }

        let data = me.el.dataset;

        if (!data.expandertarget) {
            return false;
        }

        if (me.el.parentElement === null) {
            throw new Error('parent element is required for plugin selector');
        }

        if (!me.el.parentElement.querySelector(data.expandertarget as keyof HTMLElementTagNameMap)) {
            return false;
        }

        me.target = me.el.parentElement.querySelector(data.expandertarget as keyof HTMLElementTagNameMap) as HTMLElement;

        if (me.target === null) {
            throw new Error('target element not found');
        }

        if (data.collapsedheight) {
            me.collapsedHeight = data.collapsedheight;
        }

        if (data.collapsedcls) {
            me.collapsedCls = data.collapsedcls;
        }

        me.el.classList.add(me.collapsedCls);
        me.target.classList.add(me.targetCls, me.targetCollapsedCls);

        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        let expanderBtn = this.el.querySelector('.expander__btn');

        if (expanderBtn === null) {
            throw new Error('Expander button is missing');
        }

        expanderBtn.addEventListener('click', this.toggleExpandState.bind(this));
    }

    toggleExpandState(): void {
        let me = this;

        if (me.target === null) {
            throw new Error('target element not found');
        }

        if (me.el.classList.contains(me.collapsedCls)) {
            me.target.style.height = 'auto';

            let height = me.target.clientHeight;

            me.target.style.height = '';

            setTimeout(function () {
                if (me.target === null) {
                    throw new Error('target element not found');
                }

                me.target.addEventListener('transitionend', function () {
                    PubSub.publish('ExpanderPlugin/toggleExpandStateFinished');
                });

                me.target.addEventListener('transitionend', function () {
                    if (me.target === null) {
                        throw new Error('target element not found');
                    }

                    me.el.classList.remove(me.collapsedCls);
                    me.target.classList.remove(me.targetCollapsedCls);
                }, {once: true});

                me.target.style.height = height + 'px';
            }, 1);
        } else {
            me.target.style.height = me.collapsedHeight;
            me.el.classList.add(me.collapsedCls);
            me.target.classList.add(me.targetCollapsedCls);
        }
    }
}

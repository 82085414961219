import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';
import $ from 'jquery';

export default class AccordionPlugin extends Plugin{
    constructor() {
        super('AccordionPlugin');
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.registerEvents();

        return true;
    }

    protected registerEvents() {
        $(this.el).on('shown.bs.collapse', function() {
            PubSub.publish('AccordionPlugin/accordionShown');
        });

        $(this.el).on('hidden.bs.collapse', function() {
            PubSub.publish('AccordionPlugin/accordionHidden');
        });
    }
}
import PagerPlugin from "./PagerPlugin";
import $ from 'jquery';

export default class GalleryPlugin extends PagerPlugin {
    protected carousel: HTMLElement | null;
    protected carouselSelector: string;

    constructor() {
        super('GalleryPlugin');

        this.carousel = null;

        this.carouselSelector = '.carousel';
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.carousel = this.el.querySelector(this.carouselSelector);

        this.registerEvents();

        return true;
    }

    protected registerEvents() {
        let instance = this;

        $(this.carousel!).on('slide.bs.carousel', this.beforeBSCarouselSlide.bind(this));

        window.addEventListener('resize', this.resize.bind(this));
    }

    protected beforeBSCarouselSlide(event: any) {
        this.moveToPage(this.pages!.item(event.to));
    }
}
import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';

export default class Brick03Plugin extends Plugin {
    protected body: HTMLElement | null;
    protected collapsedCls: string;
    protected copy: HTMLElement | null;
    protected image: HTMLElement | null;
    protected lastViewport: string;
    protected teaser: HTMLElement | null;
    protected teaserContainer: HTMLElement | null;
    protected text: HTMLElement | null;

    constructor() {
        super('Brick03Plugin');

        this.collapsedCls = 'expander-target--collapsed';
        this.lastViewport = '';

        this.teaser = null;
        this.text = null;
        this.teaserContainer = null;
        this.copy = null;
        this.image = null;
        this.body = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let me = this;

        super.initPlugin(htmlElement);

        if (me.el === undefined) {
            return false;
        }

        me.body = me.el.querySelector('.brick-03-media__body');
        me.copy = me.el.querySelector('.brick-03-media__text .copy');
        me.image = me.el.querySelector('.brick-03-media__image');
        me.teaser = me.el.querySelector('.brick-03-media__teaser-container .teaser');
        me.teaserContainer = me.el.querySelector('.brick-03-media__teaser-container');
        me.text = me.el.querySelector('.brick-03-media__text');
        me.siteWrapper = document.querySelector('.site-wrapper');

        window.addEventListener('scroll', me.scroll.bind(me));
        window.addEventListener('resize', me.resize.bind(me));

        me.resize();
        me.scroll();

        return true;
    }

    getViewport(): string {
        return (window.innerWidth >= 768) ? 'desktop': 'mobile';
    }

    resize(): void {
        if (this.copy === null || this.text === null) {
            return;
        }

        let isMobile = window.innerWidth < 768;

        if (this.lastViewport != this.getViewport()) {
            this.lastViewport = this.getViewport();

            this.copy.classList.toggle(this.collapsedCls, isMobile);

            (this.el.querySelector('.brick-03-media__expander') as HTMLElement).hidden = !isMobile;

            if (!isMobile) {
                this.copy.style.height = '';
            }
        }

        if (!this.el.classList.contains('brick-03-media--has-teaser')) {
            if (window.innerWidth < 768) {
                if (this.image) {
                    this.image.style.minHeight = '';
                }
            } else {
                let count = 0,
                    marginTop = (window.innerWidth > 1200) ? 100: 88,
                    minHeight1 = 0,
                    minHeight2 = 0;

                // min-height per Schleife setzen da sich die Texthöhe nach Setzen der min-height wiederum erhöhen kann
                do {
                    minHeight1 = minHeight2;

                    if (this.image) {
                        this.image.style.minHeight = minHeight2 + 'px';
                    }

                    minHeight2 = (this.text.clientHeight - marginTop);
                    count++;
                } while (count < 5 &&  minHeight1 < minHeight2);
            }
        } else if (window.innerWidth < 768) {
            if (this.teaserContainer !== null)
                this.teaserContainer.style.paddingTop = '';
        }

        PubSub.publish('Brick03Plugin/resizeAfter');

        this.scroll();
    }

    scroll(): void {
        let me = this;

        if (this.teaserContainer && this.lastViewport == 'desktop') {
            let offset = 0,
                elems = {
                    headline: this.el.querySelector('.brick-03-media__content-container .headline'),
                    subline: this.el.querySelector('.brick-03-media__content-container .subline'),
                    beam: this.el.querySelector('.brick-03-media__content-container .beam')
                } as any,
                scrollY = window.scrollY || window.pageYOffset;

            for (let i in elems) {
                if (elems[i]) {
                    offset += elems[i].parentElement.clientHeight;
                }
            }

            let minOffset = offset;

            if (this.teaser === null || this.body === null) {
                throw new Error('Can not set teaser position teaser element or body missing');
            }

            let offsetTop = (element: HTMLElement | null) => {
                let offsetTop = 0;

                while(element) {
                    offsetTop += element.offsetTop;
                    element = element.offsetParent as HTMLElement | null;
                }

                return offsetTop;
            };

            if (scrollY > this.el.offsetTop + this.body.offsetTop) {
                let headerOffset = 160;

                if (me.siteWrapper.classList.contains('site-wrapper--header-fade-out')) {
                    headerOffset = 60;
                }

                offset += Math.min(
                    scrollY - offsetTop(this.el) - this.body.offsetTop + headerOffset,
                    this.body.clientHeight - this.teaser.clientHeight
                );
            }

            if (offset < minOffset) {
                offset = minOffset;
            }

            this.teaserContainer.style.paddingTop = offset + 'px';
        }
    }
}

import Plugin from "../plugin-system/Plugin";

export default class CookieLayerPlugin extends Plugin {
    protected homepage: HTMLElement | null;
    protected homepageOpenCls: string;
    protected settings: HTMLElement | null;
    protected settingsOpenCls: string;

    constructor() {
        super('CookieLayerPlugin');

        this.homepageOpenCls = 'cookie-layer__homepage--open';
        this.settingsOpenCls = 'cookie-layer__settings--open';

        this.settings = null;
        this.homepage = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let me = this;

        super.initPlugin(htmlElement);

        if (this.el === undefined) {
            return false;
        }

        me.homepage = me.el.querySelector('.cookie-layer__homepage');
        me.settings = me.el.querySelector('.cookie-layer__settings');

        if (me.homepage === null || me.settings === null) {
            throw new Error('Cookie-layer HTMLelements are missing');
        }

        me.el.querySelector('.modal__close')?.addEventListener('click', function () {
            me.setLayerState(true, false);
        });

        if (me.el.querySelector('.cookie-layer__btn-accept-necessary') === null
            || me.el.querySelector('.cookie-layer__btn-setting') === null
            || me.homepage.querySelector('.cookie-layer__btn-accept-all') === null
            || me.settings.querySelector('.cookie-layer__btn-accept-all') === null
        ) {
            throw new Error('Button controls are missing');
        }

        me.el.querySelector('.cookie-layer__btn-accept-necessary')?.addEventListener('click', me.acceptNecessaryCookies.bind(me));
        me.el.querySelector('.cookie-layer__btn-setting')?.addEventListener('click', function () {
            me.setLayerState(false, true);
        });
        me.homepage.querySelector('.cookie-layer__btn-accept-all')?.addEventListener('click', me.saveSettings.bind(me));
        me.settings.querySelector('.cookie-layer__btn-accept-all')?.addEventListener('click', me.saveSettings.bind(me));

        if (me.showCookieLayer()) {
            setTimeout(function () {
                me.setLayerState(true, false);
            }, 1000);
        }

        let elems = [
            me.homepage,
            me.settings.querySelector('.container')
        ] as any;

        for (let i = 0; i < elems.length; i++) {
            if (elems[i].clientHeight > window.innerHeight) {
                let copy = elems[i].querySelector('.copy');

                copy.style.height = window.innerHeight - (elems[i].clientHeight - copy.clientHeight) + 'px';
            }
        }

        return true;
    }

    acceptAllCookies(): void {
        //todo: Cookieeinstellungen speichern
        this.setLayerState(false, false);
    }

    acceptNecessaryCookies(): void {
        //todo: Cookieeinstellungen speichern
        this.setLayerState(false, false);
    }

    saveSettings(): void {
        //todo: Cookieeinstellungen speichern
        this.setLayerState(false, false);
    }

    showCookieLayer(): boolean {
        //todo: Bedingung implementieren
        return true;
    }

    setLayerState(homepage: boolean, settings: boolean): void {
        this.homepage?.classList.toggle(this.homepageOpenCls, homepage);
        this.settings?.classList.toggle(this.settingsOpenCls, settings);
    }
}

import Plugin from "../plugin-system/Plugin";
import $ from 'jquery';

export default class SearchPlugin extends Plugin {
    private _body: HTMLElement | null;
    private _layer: HTMLElement | null;
    private _input: HTMLElement | null;

    constructor() {
        super('SearchPlugin');
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let me = this;

        super.initPlugin(htmlElement);

        if (me.el === undefined) {
            return false;
        }

        me._body = document.querySelector('body');
        me._layer = document.querySelector('.search-layer');
        me._input = document.querySelector('.search-field-wrapper input');

        document.addEventListener('keydown', me.onKeyDown.bind(me));
        me.el.addEventListener('click', me.onSearchIconClick.bind(me));

        if (me._body) {
            me._body.addEventListener('click', me.onBodyClick.bind(me));
        }

        me.addAutoComplete();

        return true;
    }

    onSearchIconClick(event: Event) {
        let me = this;

        if (!me._layer || !me._body) {
            return;
        }

        me._body.classList.toggle('modal-open');
        me._layer.classList.toggle('is-active');

        if (me._layer.classList.contains('is-active')) {
            me._layer.querySelector('input').focus();
        }

        event.stopPropagation();
    }

    onKeyDown(event: KeyboardEvent) {
        let me = this;

        if (!me._layer || !me._body) {
            return;
        }

        if (event.code == 'Escape') {
            me._body.classList.remove('modal-open');
            me._layer.classList.remove('is-active');
        }
    }

    onBodyClick(event: Event) {
        let target = event.target,
            me = this;

        if (!target instanceof HTMLElement || !me._layer || !me._body) {
            return;
        }

        if (!target.classList.contains('search-layer-content') && !target.closest('.search-layer-content')) {
            me._body.classList.remove('modal-open');
            me._layer.classList.remove('is-active');
        }
    }

    addAutoComplete() {
        if (!this._input) {
            return;
        }

        let $input = $(this._input);

        $input.autocomplete({
            minChars: 3,
            triggerSelectOnValidInput: false,
            lookup: function (term, done) {
                $.getJSON(
                    $input.data('suggestionsrequesturl'),
                    {
                        q: term,
                        language : $('html').attr('lang')
                    },
                    function (data) {
                        let result = {
                            suggestions: []
                        };

                        if (data.length > 0) {
                            $.each(data, function (index, suggestion) {
                                result.suggestions.push({
                                    value: suggestion
                                });
                            });
                        }

                        done(result);
                    });
            },
            onSelect: function(result) {
                $input.val(result.value);
                $input.parents('form').trigger('submit');
            },
            appendTo: $input.closest('.search-field-wrapper')
        });
    }
}

import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';

export default class Brick02Plugin extends Plugin {
    protected lastViewport: string;
    protected collapsedCls: string;
    protected copy: HTMLElement | null;

    constructor() {
        super('Brick02Plugin');

        this.lastViewport = '';
        this.collapsedCls = 'expander-target--collapsed';

        this.copy = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.copy = this.el.querySelector('.copy');

        window.addEventListener('resize', this.checkExpanderState.bind(this));

        this.checkExpanderState();

        return true;
    }

    checkExpanderState() {
        let isMobile = window.innerWidth < 768;

        if (this.copy === null) {
            throw new Error('Can not evaluate expander state. Target element is missing');
        }

        if (this.lastViewport != this.getViewport()) {
            this.lastViewport = this.getViewport();

            this.copy.classList.toggle(this.collapsedCls, isMobile);

            (this.el.querySelector('.expander') as HTMLElement).hidden = !isMobile;

            if (!isMobile) {
                this.copy.style.height = '';
            }
        }

        PubSub.publish('Brick02Plugin/checkExpanderStateAfter');
    }

    getViewport(): string {
        return (window.innerWidth >= 768) ? 'desktop': 'mobile';
    }
}
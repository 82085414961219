import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';
// @ts-ignore
import AOS from 'aos';

export default class ScrollAnimationPlugin extends Plugin {
    constructor() {
        super('ScrollAnimationPlugin');
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        this.registerEvents();

        return true;
    }

    protected registerEvents() {
        PubSub.subscribe('ExpanderPlugin/toggleExpandStateFinished', this.refreshAOS);
        PubSub.subscribe('Brick02Plugin/checkExpanderStateAfter', this.refreshAOS);
        PubSub.subscribe('Brick03Plugin/resizeAfter', this.refreshAOS);
        PubSub.subscribe('AccordionPlugin/accordionShown', this.refreshAOS);
        PubSub.subscribe('AccordionPlugin/accordionHidden', this.refreshAOS);
    }

    protected refreshAOS() {
        AOS.refresh();
    }
}
import Plugin from "../plugin-system/Plugin";

export default class PagerPlugin extends Plugin {
    protected pages: NodeListOf<HTMLElement> | null;
    protected leftControl: HTMLElement | null;
    protected rightControl: HTMLElement | null;
    protected pagerSlot: HTMLElement | null;
    protected pagerSlotInner: HTMLElement | null;
    protected currentPage: HTMLElement | null;
    protected leftControlSelector: string;
    protected rightControlSelector: string;
    protected pagesSelector: string;
    protected pagerSlotSelector: string;
    protected pagerSlotInnerSelector: string;
    protected pageActiveClass: string;

    constructor(pluginName = 'PagerPlugin') {
        super(pluginName);

        this.pages = null;

        this.leftControl = null;
        this.rightControl = null;
        this.pagerSlot = null;
        this.pagerSlotInner = null;
        this.currentPage = null;

        this.leftControlSelector = '.pager__control--left';
        this.rightControlSelector = '.pager__control--right';

        this.pageActiveClass = 'pager__item--active';

        this.pagesSelector = '.pager__item';
        this.pagerSlotSelector = '.pager__slot';
        this.pagerSlotInnerSelector = '.pager__item-list';
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        let instance = this;

        this.leftControl = this.el.querySelector(this.leftControlSelector);
        this.rightControl = this.el.querySelector(this.rightControlSelector);

        this.pagerSlot = this.el.querySelector(this.pagerSlotSelector);

        this.pages = this.el.querySelectorAll(this.pagesSelector);

        this.pagerSlotInner = this.el.querySelector(this.pagerSlotInnerSelector);

        if (this.pages.length === 0) {
            throw new Error('Can not initialize pager. No pager items given');
        }

        this.pages.forEach(function (page: HTMLElement) {
           if (page.classList.contains(instance.pageActiveClass)) {
               instance.currentPage = page;

               return false;
           }
        });

        if (this.currentPage === null) {
            this.currentPage = this.pages.item(0);
        }

        this.registerEvents();

        return true;
    }

    protected registerEvents() {
        let instance = this;

        if (this.leftControl !== null) {
            this.leftControl.addEventListener('click', this.leftClick.bind(this));
        }

        if (this.rightControl !== null) {
            this.rightControl.addEventListener('click', this.rightClick.bind(this));
        }

        window.addEventListener('resize', this.resize.bind(this));

        this.pages!.forEach(function(page: HTMLElement) {
            page.addEventListener('click', instance.pageItemClicked.bind(instance));
        });
    }

    protected resize() {
        this.moveToPage(this.currentPage!);
    }

    protected leftClick() {
        if (this.currentPage!.previousElementSibling !== null) {
            this.moveToPage(this.currentPage!.previousElementSibling as HTMLElement)
        } else {
            this.moveToPage(this.pages!.item(this.pages!.length - 1));
        }
    }

    protected rightClick() {
        if (this.currentPage!.nextElementSibling !== null) {
            this.moveToPage(this.currentPage!.nextElementSibling as HTMLElement)
        } else {
            this.moveToPage(this.pages!.item(0));
        }
    }

    protected pageItemClicked(event: MouseEvent) {
        let page = event.currentTarget as HTMLElement;

        this.moveToPage(page);
    }

    protected moveToPage(page: HTMLElement) {
        let pagerWidth = this.pagerSlot!.clientWidth,
            pagePosition = page.offsetLeft * -1,
            pageWidth = page.offsetWidth,
            lastItem = this.pages!.item(this.pages!.length - 1),
            centerOffset = pagerWidth / 2 - pageWidth / 2,
            newPosition = 0,
            trackWidth,
            leftOffset = 0,
            rightOffset = 0;

        trackWidth = lastItem.offsetWidth + lastItem.offsetLeft;

        if (trackWidth > pagerWidth) {
            newPosition = pagePosition + centerOffset;

            leftOffset = pagePosition * -1 - centerOffset;
            rightOffset = pagePosition * -1 + pageWidth + centerOffset;

            if (leftOffset < 0) {
                newPosition += leftOffset
            }

            if (rightOffset > trackWidth) {
                newPosition += rightOffset - trackWidth;
            }
        }

        this.currentPage!.classList.remove(this.pageActiveClass);
        this.currentPage = page;
        this.currentPage.classList.add(this.pageActiveClass);

        this.pagerSlotInner!.style.transform = 'translateX(' + newPosition + 'px)'
    }
}
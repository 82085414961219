import Plugin from "./Plugin";

export interface PluginQueueItem {
    [index: string]: new() => Plugin;
}

export interface PluginQueue {
    [index: string]: PluginQueueItem;
}

export default class PluginManager {
    private plugins: Array<Plugin>;
    private pluginQueue: PluginQueue;
    private documentLoaded: boolean;

    constructor() {
        this.plugins = [];
        this.pluginQueue = {};
        this.documentLoaded = false;

        document.addEventListener('DOMContentLoaded', this.initPlugins.bind(this));
    }

    /**
     *
     * @param plugin
     * @param selector
     */
    registerPlugin(pluginCallback: new() => Plugin, selector: string): void {
        if (this.documentLoaded) {
            // @toDo Prüfen wie sich das mit Plugins verhält, welche bei fertigen DOM geladen werden.
        } else {
            if (this.pluginQueue[pluginCallback.name] === undefined) {
                this.pluginQueue[pluginCallback.name] = {};
            }

            this.pluginQueue[pluginCallback.name][selector] = pluginCallback;
        }
    }

    /**
     *
     */
    public initPlugins(): void {
        if (!this.documentLoaded) {
            this.documentLoaded = true;

            for (let pluginName in this.pluginQueue) {
                let pluginQueueItem = this.pluginQueue[pluginName];

                for (let pluginSelector in pluginQueueItem) {
                    this.initPlugin(pluginSelector, pluginQueueItem[pluginSelector]);
                }
            }
        }
    }

    /**
     *
     * @param pluginSelector
     * @param pluginCallback
     */
    public initPlugin(pluginSelector: string, pluginCallback: new() => Plugin): void {
        let elements = document.querySelectorAll(pluginSelector);

        elements.forEach(function(element) {
            let plugin = new pluginCallback();
            plugin.initPlugin(element as HTMLElement);
        });
    }
}

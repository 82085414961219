import Plugin from "../plugin-system/Plugin";

export default class BackgroundManagerPlugin extends Plugin {
    protected brickList: HTMLCollection | null;

    protected lightThemeClass: string;
    protected firstOfColorClass: string;
    protected lastOfColorClass: string;
    protected colorSequenceClass: string;

    protected availableBrickClasses: Array<string>;

    constructor() {
        super('BackgroundManagerPlugin');

        this.brickList = null;

        this.lightThemeClass = 'light-theme';

        this.firstOfColorClass = 'content-element--first-of-color';
        this.lastOfColorClass = 'content-element--last-of-color';
        this.colorSequenceClass = 'content-element--color-sequence';

        this.availableBrickClasses = [
            'brick-01',
            'brick-02',
            'brick-03',
            'brick-04',
            'brick-05',
            'brick-06',
            'brick-07',
            'brick-08'
        ];
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.brickList = this.el.children;

        this.evaluateAdjustmentClasses();

        return true;
    }

    protected evaluateAdjustmentClasses() {
        let instance = this;

        if (this.brickList === null) {
            throw new Error('Can not evaluate adjustments. List of bricks is missing');
        }

        let currentBrick: HTMLElement | null = null;
        let currentBrickClass: string | null = null;

        for (let brickId = 0; brickId < this.brickList.length; brickId++) {
            if (this.brickList[brickId].children === null) {
                return;
            }

            let brick = this.brickList[brickId] as HTMLElement;
            let brickClass = this.determinateBrickClass(brick);

            if (this.brickList[brickId].classList.contains('pimcore_area_content')) {
                brick = this.brickList[brickId].children[0] as HTMLElement;
            }

            let brickHasLightTheme = brick.classList.contains(instance.lightThemeClass);

            if (currentBrick !== null) {
                let currentBrickHasLightTheme = currentBrick.classList.contains(instance.lightThemeClass);

                if (currentBrickClass === brickClass && brickHasLightTheme === currentBrickHasLightTheme) {
                    currentBrick.classList.add(this.colorSequenceClass);
                }

                if (brickHasLightTheme !== currentBrickHasLightTheme) {
                    currentBrick.classList.add(instance.lastOfColorClass);
                    brick.classList.add(instance.firstOfColorClass);
                }
            }

            currentBrick = brick;
            currentBrickClass = brickClass;
        }

        for (let brickId = this.brickList.length - 1; brickId >= 0; brickId--) {
            let brick = this.brickList[brickId] as HTMLElement;

            brick.style.position = 'relative';
            brick.style.zIndex = (this.brickList.length - brickId).toString();
        }
    }

    protected determinateBrickClass(brick: HTMLElement): string {
        this.availableBrickClasses.forEach(function(className) {
            if (brick.classList.contains(className)) {
                return className;
            }
        });

        return 'none';
    }
}
import '../scss/app.scss';
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'simplebar';
import 'devbridge-autocomplete';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/print/ls.print';

lazysizes.init();

import PluginManager from "./plugin-system/PluginManager";
import Brick03Plugin from "./plugins/Brick03Plugin";
import ExpanderPlugin from "./plugins/ExpanderPlugin";
import CookieLayerPlugin from "./plugins/CookieLayerPlugin";
import GoogleMapsPlugin from "./plugins/GoogleMapsPlugin";
import MobileNavigationPlugin from "./plugins/MobileNavigationPlugin";
import ScrollTopPlugin from './plugins/ScrollTopPlugin';
import NestedMenuPlugin from "./plugins/NestedMenuPlugin";
import ServiceBadgePlugin from "./plugins/ServiceBadgePlugin";
import BackgroundManagerPlugin from "./plugins/BackgroundManagerPlugin";
import SearchPlugin from "./plugins/SearchPlugin";
import "@babel/polyfill";
import Brick02Plugin from "./plugins/Brick02Plugin";
// @ts-ignore
import AOS from 'aos';
import ScrollAnimationPlugin from "./plugins/ScrollAnimationPlugin";
import AccordionPlugin from "./plugins/AccordionPlugin";
import PagerPlugin from "./plugins/PagerPlugin";
import GalleryPlugin from "./plugins/GalleryPlugin";
import ScrollMenuPlugin from "./plugins/ScrollMenuPlugin";

let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

/****** @toDo AOS muss nochmal überarbeitet werden sobald das Lazy-Loading integriert wurde. **/
function refresh() {
    AOS.refresh();
}

if (isIE11) {
    // @ts-ignore
    import('picturefill');
}

document.addEventListener('DOMContentLoaded', function() {
    if (isIE11) {
        document.body.classList.add('is-ie');
    }

    AOS.init({
        duration: 650,
        offset: 200
    });

    let images = document.querySelectorAll('img');

    images.forEach(function(img) {
        if (img !== null) {
            if (img.complete) {
                refresh();
            } else {
                img.addEventListener('load', refresh);
            }
        }
    });
});

const pluginManger = new PluginManager();

pluginManger.registerPlugin(ScrollAnimationPlugin, 'body');
pluginManger.registerPlugin(ExpanderPlugin, '.expander');
pluginManger.registerPlugin(Brick03Plugin, '.brick-03-media');
pluginManger.registerPlugin(Brick02Plugin, '.brick-02');
pluginManger.registerPlugin(CookieLayerPlugin, '.cookie-layer');
pluginManger.registerPlugin(GoogleMapsPlugin, '.google-maps');
pluginManger.registerPlugin(MobileNavigationPlugin, '.mobile-navigation');
pluginManger.registerPlugin(ScrollTopPlugin, '[data-scroll-top]');
pluginManger.registerPlugin(NestedMenuPlugin, '.mega-menu__container');
pluginManger.registerPlugin(ServiceBadgePlugin, '.service-badge');
pluginManger.registerPlugin(BackgroundManagerPlugin, '.content-main');
pluginManger.registerPlugin(AccordionPlugin, '.accordion__content');
pluginManger.registerPlugin(PagerPlugin, '[data-pager]');
pluginManger.registerPlugin(GalleryPlugin, '[data-gallery]');
pluginManger.registerPlugin(ScrollMenuPlugin, 'body');
pluginManger.registerPlugin(SearchPlugin, '.icon-navigation__item .icon-search');
